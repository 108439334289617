import React, { useCallback, useEffect } from 'react';
import './EpisodeHome.scss';

import { Tooltip } from '@material-ui/core';

import { NavigateNext, PlayArrow, Share as ShareIcon, 
    BookmarkBorder, ArrowUpward, Bookmark, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

import { readContents } from '../../Data/Contents';
import { readComment } from '../../Data/Comment';
import { readProfile } from '../../Data/Profile';
import { readOwnerships } from '../../Data/Ownership';
import { logBehavior, logBulkBehavior } from '../../Data/Behavior';

import Comment from './Comment';
import { flags, fn, getOffsetInScreen, isAgeAdult } from '../../Functions';

import NotFound from '../NotFound';
import Share from '../../Component/Share';

import ChannelService from '../../Component/ChannelService';
import { getTitle } from '../../Data/Title';
import { useBookmarkList } from '../../Recoil/Bookmark/Bookmark';
import { useSession } from '../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useEpisodes } from '../../Recoil/Title/Episodes';
import DisplayAds from './DisplayAds';
import EpisodeLikeBox from './EpisodeLikeBox';
import VignetteAd from './VignetteAd';
import { useToastAlert } from '@webtoontoday/toast';
import CommentOnWrite from './CommentOnWrite';
import YoutubeAD from './YoutubeAD';
import { getVinetteAd } from '../../Data/Ad';

let logs = [];
let cummulated_seen_time = {};

const EpisodeHome = ({serviceId, titleId, episodeId, snapshotId = null, hash, query}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const {episodeObject, fetch} = useEpisodes();

    //interaction
    const [ isContentsLoading, setIsContentsLoading ] = React.useState(window.predef_data && window.predef_data.contents ? false : true);

    const [ isShowingAllComments, setIsShowingAllComments ] = React.useState(false);
    const [ isContentsOnScreen, setIsContentsOnScreen ] = React.useState(true);
    const [ isMouseHoveringOnMenu, setIsMouseHoveringOnMenu ] = React.useState(false);
    const [ timeElapsedEnough, setTimeElapsedEnough ] = React.useState(false);
    const [ timeElapsedEnoughAfterClick, setTimeElapsedEnoughAfterClick ] = React.useState(true);
    const [ bodyClickTimer , setBodyClickTimer ] = React.useState(null);

    /** @type {['popularity'|'new', ()=>void]} */
    const [ commentSortBy, setCommentSortBy ] = React.useState('popularity');
    
    const episodes = React.useMemo(()=> (
        episodeObject[`${serviceId}:${titleId}`]
        ?? window.predef_data?.episodeList
        ?? []
    ), [episodeObject, serviceId, titleId]);

    const [ contents, setContents ] = React.useState(window.predef_data?.contents ?? {});
    const contentSnapshotId = React.useMemo(()=> (contents?.snapshotId ?? null), [contents]);
    
    const [ comments, setComments ] = React.useState([]);

    const nextEpisodeId = React.useMemo(() => (
        contents.nextEpisodeId
        ?? episodes[episodes.findIndex(episode => episode.episodeId === episodeId) + 1]?.episodeId
        ?? null
    ), [contents, episodes, episodeId])

    const [ profile, setProfile ] = React.useState({});
    const [ title, setTitle ] = React.useState(null);

    const [ latestAuthorComment, setLatestAuthorComment ] = React.useState({});

    const [ authorProfile, setAuthorProfile ] = React.useState({});

    const [ currentBookmark, setCurrentBookmark ] = React.useState(null);

    const [ updateComment, setUpdateComment ] = React.useState(false);
    
    const { bookmarkList, fetchBookmarkList, deleteBookmarkFromList, createBookmarkToList } = useBookmarkList();

    //const [ snapshotIdForSurvey, setSnapshotIdForSurvey ] = React.useState(null);

    const [ isShowingVignette, setIsShowingVignette ] = React.useState(false);

    const [ isEndOfJourney, setIsEndOfJourney ] = React.useState(false);

    const [ adInfo, setAdInfo ] = React.useState({videoId: "GfjXBwRxNFA", shortlink: "https://webt.ly/470/lRKDhJOZZnSHCVDp"});
    useEffect(()=>{
        (async()=>{
            if (!isContentsLoading && !nextEpisodeId) {
                const adInfo = await getVinetteAd({serviceId, titleId});
                setAdInfo(adInfo);
            }
        })();
    },[isContentsLoading, nextEpisodeId, serviceId, titleId])

    //driven variables
    
    const previousEpisodeId = episodes[episodes.findIndex(episode => episode.episodeId === episodeId) - 1]?.episodeId ?? null;
    const nextSnapshotId = contents?.nextSnapshotId ?? null;
    const nextEpisode = episodes.find(row => row.episodeId === nextEpisodeId);

    const commentLength = comments.length;
    const isNotFound = !isContentsLoading && (!contents || !contents.images);
    const isMenuBarOpen = !timeElapsedEnough || !timeElapsedEnoughAfterClick || !isContentsOnScreen || isMouseHoveringOnMenu;
    
    /*React.useEffect(() => {
        let popstateCallback;
        const urlParams = new URLSearchParams(query);

        (async() => {
            await sessionRefresh();
            if(snapshotId && query?.length > 1 && urlParams.get("utm_source") && urlParams.get("fbclid") && window.history.state !== "pushed entry" && await getIsSurveyRequired({snapshotId})){
                const firstUrl = window.location.href;
                popstateCallback = (e) => {
                    if(window.location.href === firstUrl && e.state !== "pushed entry"){
                        fn.replaceWith(`/survey/${snapshotId}`);
                    }
                }
                window.addEventListener("popstate", popstateCallback);
    
                setSnapshotIdForSurvey(snapshotId);
                window.history.pushState("pushed entry", null);
            }
        })();

        return (() => {
            window.removeEventListener("popstate", popstateCallback);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 설문 기능 동결 */

    React.useEffect(()=>{
        (async()=>{
            let userProfile = await readProfile(session.userid);
            setProfile(userProfile)
        })();
    },[session.userid]);

    React.useEffect(()=>{
        if (!title || !title.is_adult){
            return;
        }

        if (!('name' in profile)){
            return;
        }

        if ( session.memberState === 'regular' && profile && profile.certificatedBirthday) {
           
            if ( isAgeAdult(profile.certificatedBirthday) ) {
                // 성인 인증 유저
                return;

            } else {
                // 미성년 인증 유저
                toastAlert('미성년자는 만 19세가 되는 1월 1일부터 성인작품을 감상할 수 있어요.', 5000);
                fn.goto(`/contents/${serviceId}/${titleId}`)
            }
            return;
        };

        if (session.memberState === 'regular' && profile && !profile.certificatedBirthday) {
            fn.goto(`/authenticate?redirectTo=/contents/${serviceId}/${titleId}/${episodeId}`);
            return;
        };

        fn.goto(`/signin?redirectTo=/contents/${serviceId}/${titleId}/${episodeId}`);

    },[profile, title, episodeId, serviceId, titleId, toastAlert, session.memberState])

    React.useLayoutEffect(() => {
        ( async () => {
            const res = await getTitle(serviceId, titleId);
            
            setTitle(res[0]);
        })();
    },[serviceId, titleId])

    React.useEffect(()=>{
        ChannelService.ChannelIO()('hideChannelButton');

        return ()=>{
            ChannelService.ChannelIO()('showChannelButton');
        }
    },[])

    React.useEffect(()=>{

        let timer;
        
        timer = setTimeout(() => {
            setTimeElapsedEnough(true);
        }, 3000);
        
        return ()=>{
            clearTimeout(timer);
        };
    },[])

    const BodyClickHandler = () =>  {
        if(bodyClickTimer){
            clearTimeout(bodyClickTimer);
        }
        setBodyClickTimer(null);

        setTimeElapsedEnoughAfterClick(false);
        let timer;

        timer = setTimeout(()=>{
            setTimeElapsedEnoughAfterClick(true);
        },2000)

        setBodyClickTimer(timer);
    };
    
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        (async () => {
            document.querySelector('.EpisodeHomeArea').scrollTo({top: 0}) 

            setPageHeader({isHiddenForPC: true, isHiddenForMobile: true, isAppbarHidden: true})

            setIsContentsLoading(true);
            setUpdateComment(true);

            const res = await readContents(serviceId, titleId, episodeId, snapshotId);
            
            if (res && res.code === 200 && res.data){
                setContents(res.data)
            } else if (res.data.code === 403) {
                toastAlert("작품을 더 감상하려면 결제가 필요해요. 결제 페이지로 이동합니다.", 3000);
                setTimeout(()=> fn.replaceWith(`/payment/contents/${serviceId}/${titleId}/${episodeId}`), 1000);
                return ;
            } else {
                return ;
            }

            setIsContentsLoading(false);

            const urlParams = new URLSearchParams(query);
            if(snapshotId && query?.length > 1 && urlParams.get("utm_campaign") ){
                let parsedUrl = urlParams.get("utm_campaign").split("_");
                let gender = parsedUrl[parsedUrl.length-5];
                
                if(["men", "women"].includes(gender)){
                    logBehavior('fb-ad-content-landing', {serviceId, titleId, episodeId, snapshotId, gender});
                }
            }
            
            logBehavior('episode-view', {serviceId, titleId, episodeId, snapshotId: snapshotId || res.data.snapshotId});

            await fetchBookmarkList();

            const currentEpisodeBookmark = bookmarkList?.filter(bookmark => `${bookmark.serviceId}:${bookmark.titleId}:${bookmark.episodeId}` === `${serviceId}:${titleId}:${episodeId}`);

            if (currentEpisodeBookmark?.length > 0) {
                setCurrentBookmark(true);
            };
            
        })();

        (async()=>{

            fetch({serviceId, titleId});

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, episodeId, snapshotId, query, toastAlert]);

    useEffect(()=>{
        if (episodes.length > 0){
            if (episodes.filter(row => !row.isFree).length === 0){
                document.querySelector('ins')?.setAttribute('data-free-contents', 'true');
            }
        }

        const currentEpisode = episodes.find( episode => episode.episodeId === episodeId );

        setPageHeader({
            titleText: currentEpisode?.title || '', isHiddenForMobile: true, isHiddenForPC: true, isAppbarHidden: true
        })

    },[setPageHeader, episodes, episodeId])
    
    React.useEffect( () => {
        if (hash) {
            document.getElementById(hash) && document.getElementById(hash).scrollIntoView()
        }
    },[contents,hash])

    const isAutofocusOnComment = false;

    React.useEffect(()=>{
        if (serviceId && titleId && episodeId){
            if (!localStorage.getItem('content:didContentFirstSeen')){
                localStorage.setItem('content:didContentFirstSeen', 'true');
                logBehavior('content-first-seen', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId});
            }
            logBehavior('content-landing', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId});
        }

        const scrollListener = () => {
            let imageDOMs = [...document.querySelectorAll('.SlideContainer img.Contents')];
            for( let dom of imageDOMs ){
                let boundingRect = getOffsetInScreen(dom);
                if (0 < boundingRect.top && boundingRect.top + boundingRect.height < window.innerHeight){
                    if(dom.dataset.exposed !== 'true') {
                        
                        logs.push({what: 'content-cut-seen', detail: {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, cutId: dom.id}});

                        dom.dataset.exposed = 'true';
                        dom.dataset.seenFrom = new Date().getTime() / 1000;
                    }
                }else {
                    if(dom.dataset.exposed === 'true') {

                        cummulated_seen_time[dom.id] = (cummulated_seen_time[dom.id] || 0) + new Date().getTime() / 1000 - dom.dataset.seenFrom;
                        logs.push({what: 'content-cut-leave', detail: {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, cutId: dom.id, enterAt: dom.dataset.seenFrom+0, duration: cummulated_seen_time[dom.id] }});

                        dom.dataset.exposed = 'false';
                    };
                };
            };
        };

        document.querySelector('.EpisodeHomeArea') && document.querySelector('.EpisodeHomeArea').addEventListener('scroll', scrollListener);

        const logScheduler = () => {
            if (logs.length > 0){
                logBulkBehavior([...logs]);
                logs = [];
            }
        }
        let handle = setInterval(logScheduler, 1000);

        return () => {
            document.querySelector('.EpisodeHomeArea') && document.querySelector('.EpisodeHomeArea').removeEventListener('scroll', scrollListener);
            clearInterval(handle);
        }
    },[contentSnapshotId, episodeId, serviceId, snapshotId, titleId])

    const getContentIndex = () => {

        let imageDOMs = [...document.querySelectorAll('.SlideContainer img.Contents')];

        for( let dom of imageDOMs ){
            let boundingRect = getOffsetInScreen(dom);
            
            if (-110 < boundingRect.top && boundingRect.top + boundingRect.height < window.innerHeight){
                return dom.id;
            };
        };
        return null;
    };

    React.useEffect(()=>{
        (async() => {
            if (!updateComment) {
                return;
            } else {
                setUpdateComment(false);
            };
            
            const ownOwnership = await readOwnerships(serviceId, titleId)
            
            const authorOwnership = ownOwnership.filter(ownership => ownership.authority === 'owner')[0];
            
            const authorProfile = await readProfile(authorOwnership.userid);

            setAuthorProfile(authorProfile)

            let res = await readComment({serviceId, titleId, episodeId, sortBy: commentSortBy});
            
            if (!res) {
                setComments([]);
                return;
            };
            const authorComments = res
                .filter( comment => comment.authorId === authorProfile.addressid )
                .sort( (a,b) => b.id - a.id)

            const lastestComment = {...authorComments[0]};
            
            if (authorComments.length > 0) {
                setLatestAuthorComment({...authorComments[0], type: 'Author'});
            }

            const commentList = res.filter( comment => comment.id !== lastestComment.id );

            const BestCommentList = commentList.filter(comment => comment.isBest)
            const normalCommentList = commentList.filter(comment => !comment.isBest)
            
            if ( res.length > 0 ) {
                setComments(BestCommentList.concat(normalCommentList));
            } else {
                setComments([]);
            }
        })()
    },[episodeId, serviceId, titleId, updateComment, commentSortBy, authorProfile]);

    React.useEffect( () => {
        
        const scrollListener = (event) => {
            let scrollDOM = document.getElementById('CommentBoxLabel');
            
            let currentTopPos = getOffsetInScreen(scrollDOM).top;
            let currentBottomPos = getOffsetInScreen(scrollDOM).height + currentTopPos;
            
            if (currentTopPos < window.innerHeight && currentBottomPos > 0){

                setIsContentsOnScreen(false);

            } else if (currentBottomPos > 0) {

                setIsContentsOnScreen(true);
            }
        }

        document.querySelector('.EpisodeHomeArea') && document.querySelector('.EpisodeHomeArea').addEventListener('scroll', scrollListener);
        return ()=>{
            document.querySelector('.EpisodeHomeArea') && document.querySelector('.EpisodeHomeArea').removeEventListener('scroll', scrollListener);
        };
    },[isContentsOnScreen, isMenuBarOpen]);
    
    const doForEndofJourney = useCallback(() => {
        
        toastAlert('마지막화예요.\n연재를 위한 응원의 메시지를 남겨주세요.', 1000);
        setIsEndOfJourney(true);

        setTimeout(()=> {
            const authorBox = document.querySelector('.AuthorBox');
            const commentBox = document.querySelector('.WriteModeBox textarea');
            
            authorBox.scrollIntoView({behavior: 'auto', block: 'start'});
            setTimeout(()=> commentBox?.focus(), 100);
        }, 50);

    },[toastAlert]);

    return (
        <div className={'EpisodeHomeArea'} >
            {isContentsOnScreen
                ?<Tooltip title={'맨 위로'}>
                    <div 
                        className={'FloatingScrollUp'} onClick={() => 
                        document.querySelector('.EpisodeHomeArea').scrollTo({top: 0, behavior: 'smooth'})} 
                    >
                        <ArrowUpward style={{fontSize: '2rem'}} />
                    </div>
                </Tooltip>
                :<></>
            }
            <div 
                className={`Header`} style={isMenuBarOpen?{height: 65}:{height: 0, border: 0, transitionProperty: 'all'}} 
                onMouseOver={ () => setIsMouseHoveringOnMenu(true) } onMouseOut={ () => setIsMouseHoveringOnMenu(false) }
            >
                <div className={'HeaderInner'} >
                    <div className={'LogoAndPreviousEpisode'} >
                        <img src={'https://static.webtoon.today/ddah/logo-03.png'} alt={'logo03'} style={{maxWidth: 40, marginRight: 10, cursor: 'pointer'}} onClick={() => fn.goto('/')} />
                    </div>
                    <div className={'CurrentEpisode'}  >
                        <span >{ (episodes.filter(episode => episode.episodeId === episodeId)[0] || {title: ''}).title }</span>
                    </div>
                    <div className={'MobileEpisodeMenu'} >
                        <ArrowBackIos className={`MoveToTitleHomeButton`} style={{marginRight: 26}} onClick={ () => fn.goto() }  />
                        <span style={{overflow: 'hidden', width: 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ (episodes.filter(episode => episode.episodeId === episodeId)[0] || {title: ''}).title}</span>
                    </div>
                    <div className={'ButtonBox'} >
                        <Tooltip title={'공유하기'} >
                            <div>
                                <Share 
                                    log={{what: 'share-episode', detail: {serviceId, titleId, episodeId}}} 
                                    contents={
                                        <ShareIcon className={'ShareButton'} />
                                    }
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={'스크랩'} >
                            <div style={{display: 'flex', alignContent: 'center'}}>
                                {!currentBookmark
                                    ?<BookmarkBorder style={{fontSize: '1.5rem', cursor: 'pointer'}} onClick={ async () => {
                                        if (session.memberState !== 'regular'){
                                            toastAlert('로그인 후 이용할 수 있는 기능이에요.', 2000)
                                            return;
                                        };

                                        const contentIndex = getContentIndex();
                                        const createdBookmark = {serviceId, titleId, episodeId, contentIndex, title: title.title, episodeTitle: episodes.filter(episode => episode.episodeId === episodeId)[0].title, thumbnail: title.thumbnail }
                                        const response = createBookmarkToList(createdBookmark)
                                        
                                        if(!response){
                                            toastAlert('스크랩에 실패했습니다.');
                                        }

                                        setCurrentBookmark(response);
                                        toastAlert({
                                            message: <div style={{lineHeight: '20px'}} >
                                                <span style={{color: 'rgb(0,176,240)'}}>{'책갈피'}</span>{'에 등록했어요!\n마이 페이지에서 확인할 수 있어요.'}
                                            </div>
                                        })
                                    }} />
                                    :<Bookmark style={{fontSize: '1.5rem', cursor: 'pointer'}} onClick={ async () => {
                                        if (session.memberState !== 'regular'){
                                            toastAlert('로그인 후 이용할 수 있는 기능이에요.', 2000)
                                            return;
                                        };

                                        await deleteBookmarkFromList([{serviceId, titleId, episodeId}])

                                        setCurrentBookmark(null);
                                    }} />
                                }
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className={'EpisodeHomeBody'} onMouseDown={BodyClickHandler} >
                <div className={'HomeBodyInner'} >
                    {!isNotFound?<>
                        <div>
                            <div className={`SlideContainer`}>
                                <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                                    {(contents.images || []).map(slide => (
                                        <img
                                            onContextMenu={(e)=>{e.preventDefault();}}
                                            key={slide}
                                            className={'Contents'}
                                            id={slide.split('/').reverse()[0].split('.').reverse()[1]}
                                            src={slide} alt={'slides'}
                                        />
                                    ))}
                                </div>
                                {(serviceId==="today")?<DisplayAds style={{width: window.innerWidth > 690?690:300, height: 300}} />:<></>}
                                <div>
                                    {flags.isClient?<EpisodeLikeBox serviceId={serviceId} titleId={titleId} episodeId={episodeId} />:<></>}
                                </div>
                            </div>
                        </div>
                        {(()=>{
                            return <>
                                <a className={`NextEpButton`}
                                    href={`/contents/${serviceId}/${titleId}/${nextEpisode?.episodeId || (Number(episodeId) + 1)}${nextSnapshotId && nextSnapshotId !== 'NULL'?`/${nextSnapshotId}`:''}`}
                                    onClick={(e)=>{
                                        if (!nextEpisode){
                                            logBehavior('content-next', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, nextEpisodeId, nextSnapshotId});
                                            e.preventDefault();
                                            e.stopPropagation();

                                            doForEndofJourney();
                                            
                                            return;
                                        }
                                        logBehavior('content-next', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, nextEpisodeId, nextSnapshotId});
                                        if (!nextEpisode.isFree && !nextEpisode.purchase){
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsShowingVignette(isShowingVignette => !isShowingVignette)
                                        }else {
                                            fn.gotoByAnchor(e);
                                        }
                                    }}
                                >
                                    <div className={`Thumbnail`}>
                                        <img src={nextEpisode?.thumbnail || title?.thumbnail} alt={nextEpisode?.title}/>
                                    </div>
                                    <div className={`TextBox`}>
                                        <div className={`Title`}>{nextEpisode?.title || `${(Number(episodeId) + 1)} 화`}</div>
                                        <div className={`Subtitle`}>{
                                        (!nextEpisode || nextEpisode.isFree)?'다음화 보기'
                                        :!nextEpisode.purchase?<><span>{'다음화 보기'}</span><span style={{color: 'rgb(189,189,194)'}}>{' | '}</span><span>{'광고 시청 후 무료'}</span></>
                                        :'다음화 보기(결제 완료)'}</div>
                                    </div>
                                    <div className='Padding'></div>
                                    <div className='Next'><ArrowForwardIos/></div>
                                    
                                </a>
                                {(nextEpisode && !nextEpisode.isFree && !nextEpisode.purchase) &&
                                <a
                                    className={'PaymentButton'}
                                    href={`/contents/${serviceId}/${titleId}/${nextEpisode.episodeId}${nextSnapshotId && nextSnapshotId !== 'NULL'?`/${nextSnapshotId}`:''}`}
                                    onClick={(e) => {
                                        logBehavior('content-next', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, nextEpisodeId, nextSnapshotId});
                                        fn.gotoByAnchor(e);
                                    }}
                                >
                                    <span>{"전 회차 결제하고 광고 없이 감상하기"}</span>
                                    
                                    <div className={`Nudge`}>
                                        <div className='Arrow'></div>
                                        <div className='Arrow2'></div>
                                        {`지금 결제하면 90% 할인`}
                                    </div>
                                </a>}
                            </>
                        })()}
                        <div className={`AuthorBox`} >
                            <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=>fn.goto(`/search?query=${encodeURIComponent(authorProfile.name)}&type=author&limit=50`)} >
                                <div className={'ImageBox'} >
                                    {authorProfile.image
                                        ?<img src={authorProfile.image} alt={'profileImg'} style={{width:65, height:65, borderRadius: '50%'}} />
                                        :<img src={"https://static.webtoon.today/noprofile.jpg"} alt={'profileImg'} style={{width:65, height:65, borderRadius: '50%'}} />
                                    }
                                </div>
                                <div className={'NameBox'} >
                                    <div className={'Name'} >
                                        {authorProfile.name}<span>{` 작가`}</span>
                                    </div>
                                    <NavigateNext />
                                </div>
                            </div>
                        </div>
                        <div className={`DividingBoxLine`} />
                        <CommentOnWrite {...{
                            profile,
                            serviceId, titleId, episodeId, author:authorProfile.name, authorid: authorProfile.addressid || authorProfile.userid,
                            isAutofocusOnComment,
                            setUpdateComment,
                            comments
                        }}/>
                        <div className={`DividingBoxLine`} />

                        {comments.length > 0 || latestAuthorComment.id
                            ?<div className={'CommentContainer'} >
                                <div className={`CommentBoxLabel`} id={'CommentBoxLabel'} >
                                    {`댓글 (${commentLength || 0}개)`}
                                    <div className={'SortBar'} >
                                        <div className={`SortStandard ${commentSortBy === 'popularity'?'Focused':''}`} onClick={ () => 
                                            setCommentSortBy('popularity')} 
                                        >
                                            {'인기순'}
                                        </div>
                                        <div className={'DividingSortStandardLine'} >{'l'}</div>
                                        <div className={`SortStandard ${commentSortBy === 'new'?'Focused':''}`} onClick={ () => setCommentSortBy('new')} >
                                            {'최신순'}
                                        </div>
                                    </div>
                                </div>
                                {latestAuthorComment.id?<Comment 
                                    comment={latestAuthorComment} setComment={setLatestAuthorComment} serviceId={serviceId} titleId={titleId} 
                                    episodeId={episodeId} deleteCallback={ () => setUpdateComment(true) }
                                />:<></>}
                                {(!isShowingAllComments
                                    ?comments.sort( (a,b) => commentSortBy === 'popularity'?b.likeCount - a.likeCount:b.createdAt - a.createdAt ).slice(0, 2)
                                    :comments.sort( (a,b) => commentSortBy === 'popularity'?b.likeCount - a.likeCount:b.createdAt - a.createdAt ))
                                        .map((comment,idx) => (
                                            <Comment 
                                                comment={comment} setComment={(newComment) => setComments([...(comments.slice(0,idx)), newComment, ...(comments.slice(idx+1))])} key={`${comment.id}:${isShowingAllComments}`} serviceId={serviceId} titleId={titleId} 
                                                episodeId={episodeId} deleteCallback={ () => setUpdateComment(true) }
                                            />
                                        )
                                )}
                                {!isShowingAllComments && comments.length > 2
                                    ?<div className={'AllCommentOpenButton'} onClick={ () => setIsShowingAllComments(true)}>
                                        {`전체 댓글 보기`}
                                    </div>
                                    :<></>
                                }
                            </div>
                            :<div className={'EmptyCommentContainer'}  >
                                {`어라, 아직 댓글이 없네요.
                                독자님이 첫 흔적을 남길 수 있는 기회예요!`}
                            </div>
                        }
                        <div style={{textAlign: 'right', width: '100%', marginBottom: 10}}>
                            <div className={'ReportButton'} onClick={() => {
                                ChannelService.ChannelIO()('openChat')
                            }}>
                                {'신고하기'}
                            </div>
                        </div>
                        {isShowingVignette && <VignetteAd serviceId={serviceId} titleId={titleId} episodeId={nextEpisodeId} onClose={()=>{setIsShowingVignette(false)}} />}
                        {isEndOfJourney && <div className={"AdArea"} style={{height: 820}}>
                            <div className={`DividingBoxLine`} />
                                <div className={"AdContent"}>
                                    <div className={"Detail"}>
                                        <div className={"Title"}>{`<${title.title}> 작품과 유사한 연재작은?`}</div>
                                        <div className={"Subtitle"}>{`유사한 연재작을 많은 회차로 즐겨보세요!`}</div>
                                    </div>
                                    <YoutubeAD videoId={adInfo.videoId} shortlink={adInfo.shortlink}/>
                            </div>
                        </div>}
                    </>
                    :<NotFound />}
                </div>
            </div>
            <div 
                className={`EpisodeFooter`} style={isMenuBarOpen?{height: 65}:{height: 0, border: 0, transitionProperty: 'all'}} 
                onMouseOver={ () => setIsMouseHoveringOnMenu(true) } onMouseOut={ () => setIsMouseHoveringOnMenu(false) }
            >
                <div className={'FooterInner'} >
                    <div className={`PreviousButton ${previousEpisodeId && previousEpisodeId !== 'NULL'?'ActivePrevious':'PassivePrevious'}`} onClick={ () => {
                        fn.goto(`/contents/${serviceId}/${titleId}/${previousEpisodeId}`)
                    } } >
                        <PlayArrow style={{transform: 'rotate(180deg)'}} />
                        <span>{'이전 화 '}</span>
                    </div>
                    <div style={{padding: 20, cursor: 'pointer'}} onClick={ () => fn.goto(`/contents/${serviceId}/${titleId}`) } >
                        {'목록'}
                    </div>
                    <div className={`NextButton `} onClick={ () => {
                        logBehavior('content-next', {serviceId, titleId, episodeId, snapshotId: snapshotId || contentSnapshotId, nextEpisodeId, nextSnapshotId});
                        if (!nextEpisodeId || nextEpisodeId === 'NULL') { 
                            /*if ( snapshotIdForSurvey ) {
                                // 설문조사 대상이므로 저장된 snapshotId의 설문조사 페이지로 이동
                                fn.goto(`/survey/${snapshotIdForSurvey}`);
                            } else {
                            } 설문 기능 동결 */
                            
                            doForEndofJourney();
                        } else {
                            fn.goto(`/contents/${serviceId}/${titleId}/${nextEpisodeId}${nextSnapshotId && nextSnapshotId !== 'NULL'?`/${nextSnapshotId}`:''}`)
                        };

                    }} >
                        <span>{'다음 화'}</span>
                        <PlayArrow />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EpisodeHome;