
import axios from 'axios'
import ss from './Session';

export const requestVignetteConfirm = async ({serviceId, titleId, episodeId}:{serviceId: string, titleId: string, episodeId: string}) => {
    let res = await axios.put('https://challenge-api.webtoon.today/webtoon/ad', {
        serviceId, titleId, episodeId
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    return res.data?.code === 200;
}

export const getVinetteAd = async ({serviceId, titleId}: {serviceId: string, titleId: string}): Promise<{videoId: string, shortlink: string}> => {

    try {

        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/ad', {
            serviceId, titleId
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
    
        if (res.status !== 200 || !res.data || !res.data.data) {
            throw new Error('invalid response');
        }

        return res.data.data;
    } catch (e) {
        console.error(e);
        return {videoId: "GfjXBwRxNFA", shortlink: "https://webt.ly/470/lRKDhJOZZnSHCVDp"}; // 육식고 - 지식똥개
    }

}