
import axios from 'axios';
import ss from './Session';

export type announcementType = {
    prizeAnnouncementTitle: string,
    winnerPrizes: {image: string, url: string}[],
    schoolPrizes: {image: string, urls: string[]}[],
    standardAndStatistics: string[],
    readerStatistics: {image: string, url: string}[],
    interactionStatistics: {image: string, url: string}[],
    ceremonyStatistics: string,
}

export const readSchoolCompetition =  async (): Promise<announcementType |null>  =>  {

    let res = await axios.get(`https://challenge-api.webtoon.today/static/school-competition`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    try {

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else {
            return null;
        }
    } catch (e) {
        return null;
    }
}
export const read2024SchoolCompetition = async (): Promise<univ2024ResultType | null> => {
    
    let res = await axios.get('https://challenge-api.webtoon.today/static/school-competition?year=2024 ', {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    try {

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

type Award = {
    logo: string;
    winners: [string, string][];
};
type UnivAward = {
    logo: string;
    winners: [string, string][][];
};

type GenderPickCategory = {
    title: string;
    author: string;
    thumbnail: string;
    link: string;
};

type GenderPick = {
    all: GenderPickCategory[];
    under24: GenderPickCategory[];
    over25: GenderPickCategory[];
};

type ImageData = {
    image: string;
    url: string;
};

export type univ2024ResultType = {
    MainAward: Award;
    SpecialAward: UnivAward;
    GenderPick: {
        women: GenderPick;
        men: GenderPick;
    };
    slideImages: ImageData[];
    cardTypeImages: ImageData[];
}