import React, { useEffect, useState } from "react";
import { requestVignetteConfirm } from "../../Data/Ad";
import { getProductInfo } from '../../Data/Product';

import './VignetteAd.scss';
import { fn } from "../../Functions";
import { useSession } from "../../Recoil/Session/Session";
import { useEpisodes } from "../../Recoil/Title/Episodes";
import { IconButton } from "@material-ui/core";
import { useToastAlert } from '@webtoontoday/toast';
import YoutubeAD from "./YoutubeAD";

const VignetteAd = ({serviceId, titleId, episodeId, onClose=()=>{}}: {serviceId: string, titleId: string, episodeId: string, onClose: ()=>void}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const {episodeObject, refresh} = useEpisodes();

    const [remainTime, setRemainTime] = useState(25);

    useEffect(()=>{
        if (remainTime <= 0){
            return;
        }

        setTimeout(()=>{
            setRemainTime(remainTime => remainTime - 1);
        }, 1000)
    }, [remainTime])

    useEffect(()=>{
        if (remainTime === 5) {
            requestVignetteConfirm({serviceId,titleId,episodeId});
        }

        if (remainTime === 1) {
            refresh({serviceId, titleId})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, episodeId, remainTime])

    const [ productAllInfo, setProductAllInfo ] = useState<{name: string, detail: string, image: string, amount: number, finalAmount: number}>({name: "", detail: "", image: "", amount: 0, finalAmount: 0});
    const episodes = episodeObject[`${serviceId}:${titleId}`];

    useEffect(() => {
        if (!session.name){
            return;
        }
        (async () => {
            const result = await getProductInfo(`${serviceId}:${titleId}`);
            if(result){
                setProductAllInfo(result);
            }else{
                toastAlert('상품을 불러올 수 없습니다.\n문의하기를 이용해주세요.', 5000);
            }
        })();
    },[episodeId, serviceId, titleId, session, toastAlert])

    return (<div className={'VignetteAdBackground'}>
        <div className={'CenterColumn'}>
            <IconButton className={'CloseButton'} onClick={onClose}>{"×"}</IconButton>
            <div className={"VignetteHeader"}>
                <span className={'Punchline'}>{`전 회차를 ${(Math.floor(productAllInfo.amount - productAllInfo.finalAmount) / productAllInfo.amount * 100)}% 할인된 ${productAllInfo.finalAmount} 원에 열람하세요`}</span>
                <div className={'ButtonToPurchase'} onClick={()=>{
                    fn.goto(`/payment/contents/${serviceId}/${titleId}/${episodeId}`)
                    onClose();
                }}>
                    <span>{`총 ${episodes.filter(row => !row.isFree).length}회차`}</span>
                    <span style={{color: 'rgba(25,24,27,0.7)'}}>{' | '}</span>
                    <span>{`${(Math.floor(productAllInfo.amount - productAllInfo.finalAmount) / productAllInfo.amount * 100)}% 할인받고 열람하기`}</span>
                </div>
            </div>
            <div className={'VignetteAd'}>
                {/*<DisplayAds style={window.innerWidth>500?{width: 500, height: 500}:{width: 340, height:400}} adSlot={"8848415274"} />*/}
                <YoutubeAD videoId={'GfjXBwRxNFA'} shortlink={"https://webt.ly/470/lRKDhJOZZnSHCVDp"} />
            </div>
            <div className={"VignetteFooter"}>
                {remainTime > 0
                ?<span className={'Punchline'}>{"다음 회차 무료 열람까지"}</span>
                :<span className={'Punchline'}>{"다음 회차를 무료로 볼 수 있어요"}</span>}
                {remainTime > 0
                ?<div className={'ButtonToSkip'}>{`${remainTime}초`}</div>
                :<div className={'ButtonToSkip'} onClick={()=>{
                    fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}`);
                    onClose();
                    window.scrollTo(0,0);
                }}>{`${episodeId}화 무료 열람 >`}</div>}
            </div>
        </div>
    </div>);
}

export default VignetteAd;