import React, { useState } from "react";
import { useSession } from "../../Recoil/Session/Session";
import { profileType } from "../../Data/Profile";
import { Button, TextField } from "@material-ui/core";
import { createComment } from '../../Data/Comment';
import { useToastAlert } from "@webtoontoday/toast";
import { logBehavior } from "../../Data/Behavior";
import { createFuzzyMatcher, getCaretRect, randomString, unique } from "../../Functions";


const CommentOnWrite = ({
    profile,
    serviceId, titleId, episodeId, author, authorid,
    isAutofocusOnComment,
    setUpdateComment,
    comments
}: {
    profile: profileType,
    serviceId: string, titleId: string, episodeId: string, author: string, authorid: string,
    isAutofocusOnComment: boolean,
    setUpdateComment: React.Dispatch<React.SetStateAction<boolean>>,
    comments: {
        type?: 'Author',
        authorId: string, name:string,
        createdAt: number,
        content: string,
        reaction: 'like'|'dislike'|'sadness'|null,
        isBest: boolean,
        likeCount: number, dislikeCount: number, sadnessCount: number,
    }[]
}) => {
    const {toastAlert} = useToastAlert();
    const {session} = useSession();
    const [ isBlocked, setIsBlocked ] = useState<boolean>(false);
    const [ isCommentMode, setIsCommentMode ] = useState<boolean>(false);
    const [ editingCommentText, setEditingCommentText ] = useState<string>('');

    const [searchingUserPrefix, setSearchingUserPrefix] = useState<RegExp | null>(null);

    const [hoveredSuggestionUniqueId, ] = useState(randomString(16));

    const [hoveredSuggestionPosition, setHoveredSuggestionPosition] = useState({top: 0, left: 0});
    const [hoveredSuggestionIndex, setHoveredSuggestionIndex] = useState(-1);

    const engagedUsers = [
        `${author}:${authorid}`,
        ...comments.map(comment => `${comment.name}:${comment.authorId}`)
    ].filter(unique).map(merged => merged.split(':')).map(([name, userid]) => ({name, userid}));

    return <div className={'WriteModeBox'} >
        <div className={'TextField'} >
            <div className={'AuthorName'} >
                {profile.name || '무'}
            </div>
            <TextField
                fullWidth minRows={2} multiline autoFocus={session.memberState === 'regular' && (isCommentMode || isAutofocusOnComment)}
                value={editingCommentText} placeholder={`다정한 응원의 메시지를 남겨주세요. ${author} 작가님에게 전달됩니다.`}
                onKeyDown={(e) => {
                    if (searchingUserPrefix){
                        const candidates = engagedUsers.filter(({name}) => searchingUserPrefix.test(name));
                        if (e.key === "ArrowUp" || (e.shiftKey && e.key === "Tab")) {
                            setHoveredSuggestionIndex(idx => Math.max((idx-1) % candidates.length, 0));
                            e.preventDefault();
                            return;
                        }else if (e.key === "ArrowDown" || e.key === "Tab") {
                            setHoveredSuggestionIndex(idx => Math.min((idx+1) % candidates.length, candidates.length));
    
                            e.preventDefault();
                            return;
                        }else if (e.key === "Enter") {
                            if (hoveredSuggestionIndex >= 0){
                                setEditingCommentText(
                                    editingCommentText => editingCommentText.replace(/@([^@]+)$/,`@${candidates[hoveredSuggestionIndex].name} `)
                                );
                                e.preventDefault();
                            }
                            setHoveredSuggestionIndex(-1);
                            setSearchingUserPrefix(null);
                            return;
                        }
                    }
                }}
                onChange={ (e) => {
                    if (e.target.value.length > 500) {
                        toastAlert('댓글은 500글자가 넘을 수 없습니다.');
                        return;
                    }
                    
                    setEditingCommentText(e.target.value);

                    let taggedUsernamePrefixRegex = /@([^@]+)$/g;

                    let tagged = (taggedUsernamePrefixRegex.exec(e.target.value) || [])[1];
                    let matched = !tagged ? null : engagedUsers.filter(({name}) => createFuzzyMatcher(tagged).test(name))[0];
                    
                    if (!tagged || !matched) {
                        setSearchingUserPrefix(null);
                        return;
                    }

                    setSearchingUserPrefix(createFuzzyMatcher(tagged));

                    let caretRect = getCaretRect();
                    
                    if (caretRect){
                        setHoveredSuggestionPosition({
                            top: caretRect.y + 16,
                            left: caretRect.x
                        });
                    }


                } }
            />
        </div>
        <div className={'ButtonField'} >
            <div className={'TextLength'} style={{marginLeft: 16}} >
                {`${editingCommentText.length || 0} / 500`}
            </div>
            <Button size={'small'} style={{borderRadius: 5}} variant={'contained'} color={'primary'} 
                disabled={isBlocked || !editingCommentText} disableElevation
                onClick={ async () => {
                    setIsBlocked(true);

                    const res = await createComment({serviceId, titleId, episodeId, content: editingCommentText});
                    
                    const taggedUsers = engagedUsers.filter(({name, userid}) => (
                        editingCommentText.includes(`@${name}`)
                    ))

                    if (res) {
                        setEditingCommentText('');
                        setIsCommentMode(false);
                        setUpdateComment(true);
                        logBehavior('put-comment', {serviceId, titleId, episodeId, content: editingCommentText, taggedUsers});
                    } else {
                        console.error('error: createComment fail')
                    };
                    
                    setIsBlocked(false);
                }}
            >
                {'등록'}
            </Button>
        </div>
        {searchingUserPrefix && <div id={hoveredSuggestionUniqueId} className="HoveredSuggestion" style={hoveredSuggestionPosition}>
            {engagedUsers.filter(({name}) => searchingUserPrefix.test(name)).map(({name}, idx) => 
                <div
                    key={name}
                    className={idx === hoveredSuggestionIndex?'Focused':''}
                    onClick={()=>{
                        
                        
                        setEditingCommentText(
                            editingCommentText => editingCommentText.replace(/@([^@]+)$/,`@${name} `)
                        );
                    
                        setHoveredSuggestionIndex(-1);
                        setSearchingUserPrefix(null);
                    }}
                    onMouseEnter={()=>{
                        setHoveredSuggestionIndex(idx);
                    }}
                >{name}</div>
            )}
        </div>}
    </div>;
}

export default CommentOnWrite;