import axios from 'axios';
import ss from './Session';

export type questionType = {
    type: string,
    key: string,
    title: string,
    subtitle?: string,
    optional_clause?: {key: string, values: (string|number)[]},
    indent?: 0|1|2|3,
    isRequired?: boolean,
} & (
    {type: 'notice'}
|   {type: 'subjective', }
|   {type: 'range', range: [number, number], caption_max?: string, caption_min?: string}
|   {type: 'single', options: string[], isToggle: boolean, allowOtherOption?: boolean}
|   {type: 'multiple', options: string[], isToggle: boolean, allowOtherOption?: boolean, caption_true?: string, caption_false?: string}
)
export type questionaireType = questionType[];

export type readSurveyCommonType = {
    createdAt: number,
    surveyId: string,
    data: Object,
}

export async function readSurvey (surveyId: string): Promise<readSurveyCommonType[] | null>  {
    try {
        let res = await axios.get(`https://challenge-api.webtoon.today/ugc/survey?surveyId=${surveyId}`, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        }
    }catch(e){
        console.error("couldn't read survey results!",e);
        return null;
    }
}

export async function checkSurveyResponseExists (surveyId: string): Promise<{isResponseExists: boolean} | null> {
    try {
        let res = await axios.get(`https://challenge-api.webtoon.today/ugc/survey?type=check&surveyId=${surveyId}`, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        }
    }catch(e){
        console.error("couldn't read survey results!",e);
        return null;
    }
}

export async function readQuestionaire (surveyId: string): Promise<questionaireType> { 
    return surveys[surveyId] || [];
}

export async function sendSurveyResponse ({surveyId, data}:{surveyId: string, data: {response: Object}}): Promise<boolean> {
    try{
        let res = await axios.put(`https://challenge-api.webtoon.today/ugc/survey`, {surveyId, data} , {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return true;

        } else {
            return false;

        }
    }catch(e){
        return false;
    }
}

export const getIsSurveyRequired = async ({snapshotId}: {snapshotId: string}): Promise<boolean> => {
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/user/is-survey-required?snapshotId=${snapshotId}`, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;

        } else {
            return false;

        }
    }catch(e){
        return false;
    }
}

export const getSurveyFormat = async ({surveyId}: {surveyId: string}): Promise<questionaireType | null> => {
    
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/ugc/survey?type=format&surveyId=${surveyId}`, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return JSON.parse(res.data.data.data);

        } else {
            return null;

        }
    }catch(e){
        return null;
    }
}


const surveys: {[key: string]: questionaireType} = {
    "analyticsSurvey" : [
        {
            type: 'notice',
            key: 'notice',
            title: "웹툰 애널리틱스(데이터 분석) 평가 설문",
            subtitle: "오늘의웹툰 데이터 분석을 이용해주셔서 감사합니다. 약 3분 정도 소요되는 간단한 설문입니다. 본 평가의견은 익명으로 관리되며 향후 서비스 개선에 사용됩니다."
        },
        {
            type: 'range',
            range: [0, 10],
            key: 'satisfaction_score',
            title: '데이터분석은 만족?',
            subtitle: '테스트',
            isRequired: true,
            caption_max: '매우 만족',
            caption_min: '매우 불만족',
        },
        {
            type: 'multiple',
            key: 'reason_satisfaction_score',
            title: "1-1 어떤 점이 만족 또는 불만족?",
            isToggle: false,
            options: [
                "디렉터의 피드백",
                "성별, 연령 분석",
                "독자 유입률, 연독률 분석",
                "컷별 체류시간, 이탈 그래프"
            ],
        },
        {
            type: 'multiple',
            key: 'reason_recommendation',
            title: "2-2. 추천하신다면 그 이유는 무엇인가요?",
            isToggle: true,
            options: [
                "디렉터의 피드백",
                "성별, 연령 분석",
                "독자 유입률, 연독률 분석",
                "컷별 체류시간, 이탈 그래프"
            ],
            allowOtherOption: true,
        }

    ],

    "2022univ": [
        {
            type: 'notice',
            key: 'notice',
            title: "과제부활전 참여 경험 설문",
            subtitle: "오늘의웹툰 '과제 부활전'에 참여해주셔서 감사합니다. 약 3분 정도 소요되는 간단한 설문입니다. 본 평가의견은 익명으로 관리되며 향후 서비스 개선에 활용됩니다. ※ 설문을 제출하시면 데이터 분석 결과를 볼 수 있습니다."
        },
        {
            type: 'notice',
            key: 'notice_artist',
            title: "먼저 작가님에 대한 질문입니다.",
        },
        {
            type: 'single',
            key: 'artist_school',
            title: "1. 현재 학업 상태를 알려주세요.",
            isRequired: true,
            isToggle: true,
            allowOtherOption: true,
            options: [
                "고등학교 재학 중",
                "고등학교 졸업",
                "대학교 재학 중",
                "대학교 졸업"
            ],
        },
        {
            type: 'single',
            key: 'artist_status',
            title: "2. 현재 작품 활동을 하고 계신 상태를 알려주세요.",
            isRequired: true,
            isToggle: true,
            allowOtherOption: true,
            options: [
                "상업 작품을 연재 중",
                "상업 작품을 투고준비 중",
                "화실, 스튜디오 등에서 근무 중",
                "따로 원고 작업을 하고 있지 않음",
            ],
        },
        {
            type: 'notice',
            key: 'notice_copetition',
            title: "공모전에 대한 질문입니다."
        },
        {
            type: 'multiple',
            key: 'competition_source',
            title: "3. 공모전 정보를 주로 어떻게 찾으시나요?(중복선택)",
            isToggle: true,
            allowOtherOption: true,
            options: [
                "지인",
                "학과 내 공지",
                "작가 카톡방",
                "공모전 정보 사이트",
                "카페",
            ],
        },
        {
            type: 'single',
            key: 'competition_thiscase',
            title: "4. '과제 부활전'을 어떻게 알게 되셨나요?",
            isRequired: true,
            isToggle: true,
            allowOtherOption: true,
            options: [
                "지인",
                "학과 내 공지",
                "작가 카톡방",
                "공모전 정보 사이트",
                "카페",
            ],
        },
        {
            type: 'multiple',
            key: 'competition_reason',
            title: "5. '과제 부활전'에 참여한 이유는 무엇인가요?(중복선택)",
            isRequired: true,
            isToggle: true,
            allowOtherOption: true,
            options: [
                "상금 금액",
                "공모전 참여 경험을 쌓기 위해",
                "공모전 수상과 작품 저작권 계약이 무관해서",
                "내 작품에 대한 독자반응이 궁금해서",
            ],
        },
        {
            type: 'single',
            key: 'competition_experience',
            title: "6. 다른 공모전에 참여한 경험이 있나요?",
            isRequired: true,
            isToggle: false,
            options: [
                "예",
                "아니오",
            ],
        },
        {
            type: 'subjective',
            key: 'competition_othercompetition',
            indent: 1,
            title: "6-1. 2022년에 어떤 공모전에 참여하셨나요?",
            optional_clause: {key: 'competition_experience', values: ["예"]},
        },
        {
            type: 'subjective',
            key: 'competition_compare',
            title: "7. 다른 공모전 대비 과제부활전에 참여하기로 결정한 이유가 있으신가요?",
            isRequired: true,
        },
        {
            type: 'single',
            key: 'competition_plan',
            title: "8. 2023년에도 공모전에 참여할 계획이 있으신가요?",
            isRequired: true,
            isToggle: false,
            options: [
                "예",
                "아니오",
            ],
        },
        {
            type: 'subjective',
            key: 'competition_othercompetitionplan',
            indent: 1,
            title: "8-1. 그렇다면 어떤 공모전에 참여하실 계획이신가요?",
            optional_clause: {key: 'competition_plan', values: ["예"]},
        },
        {
            type: 'notice',
            key: 'notice_2022univ',
            title: "'과제 부활전' 참여경험에 대한 질문입니다.",
        },
        {
            type: 'range',
            range: [0, 10],
            key: 'competition_submit_satisfy',
            title: "9. '과제 부활전' 접수과정은 만족스러우셨나요?",
            isRequired: true,
            caption_max: "매우 그렇다",
            caption_min: "전혀 그렇지 않다",
        },
        {
            type: 'subjective',
            key: 'competition_submit_improvement',
            indent: 1,
            title: "9-1. 접수과정에서 무엇을 개선하면 좋을까요?",
            optional_clause: {key: 'competition_submit_satisfy', values: [0,1,2,3,4,5,6,7,8]},
        },
        {
            type: 'range',
            range: [0, 10],
            key: 'competition_recommend',
            title: "10. '과제 부활전'을 다른 웹툰 작가/작가지망생에게 추천할 의사가 있나요?",
            isRequired: true,
            caption_max: "매우 그렇다",
            caption_min: "전혀 그렇지 않다",
        },
        {
            type: 'subjective',
            key: 'competition_recommend_reason',
            indent: 1,
            title: "10-1. 추천할 의사가 있다면 왜인가요?",
            optional_clause: {key: 'competition_recommend', values: [7,8,9,10]},
        },
        {
            type: 'subjective',
            key: 'competition_no_recommend_reason',
            indent: 1,
            title: "10-1. 추천할 의사가 없다면 왜인가요?",
            optional_clause: {key: 'competition_recommend', values: [0,1,2,3,4,5,6,7,8]},
        },
    ]
}