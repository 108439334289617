import { Button } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { fn } from '../../../Functions';
import AdsSlides from '../../../Component/AdsSlides';
import './Applying2024University.scss';

import { logBehavior } from '../../../Data/Behavior';
import { AnalyticsSubmit } from '../../../Data/AnalyticsSubmit';
import { getTitle, updateTitle } from '../../../Data/Title';
import LoadingCircle from '../../../Component/LoadingCircle';
import { useSession } from '../../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../Recoil/PageHeader/PageHeader';
import { useToastAlert } from '@webtoontoday/toast';
import { useTitles } from '../../../Recoil/Title/Titles';
import { useEpisodes } from '../../../Recoil/Title/Episodes';
import TextCheckBox from '../../../Component/TextCheckBox';
import { GuideTextBoxForGeneral } from '../../../Component/TextBox';
import WithLabel from '../../../Component/WithLabel';
import { readUnivReport } from '../../../Data/Report';
import AutocompletableTextField from '../../../Component/AutoCompletableTextField';
import TextArea from '../../Enroll/Component/TextArea';

const currentTermsofuseVersion = "/termsofuse/termsofuse/analytics/20220202/kr";

const AgreementsLabel: {[key: string]: string} = {
    marketingAgreement: "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    webtoonCopyingAgreement: "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    analysisCopyingAgreement: "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    platformUploadAgreement: "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
} as const


const consentList = [
    "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
];

type Applying2024AgreementType = {
    marketingAgreement: boolean,
    webtoonCopyingAgreement: boolean,
    analysisCopyingAgreement: boolean,
    platformUploadAgreement: boolean,
};

const exposureLabels = {
    public: "공개하기",
    unlist: "홈에서는 비공개",
    private: "일단은 나만 보기",
} as const

const maxLength = 25;
const phoneRegExp = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;

const Applying2024University = ({serviceId, titleId}:{serviceId: string, titleId: string}) => {
    const { session } = useSession();
    const { fetch: fetchTitle, titleObject, refreshTitle } = useTitles();
    const { fetch: fetchEpisode, episodeObject } = useEpisodes();
    const { toastAlert } = useToastAlert();
    

    const [ agreements, setAgreements ] = useState<Applying2024AgreementType>({
        marketingAgreement: false,
        webtoonCopyingAgreement: false,
        analysisCopyingAgreement: false,
        platformUploadAgreement: false,
    })

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '과제부활전 신청'})
    },[setPageHeader])
    
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<TitleType|undefined>(undefined);
    const [hasTriedApplying, setHasTriedApplying] = React.useState<boolean>(false);
    
    const [ episodeRange, setEpisodeRange ] = React.useState<[number,number]|null>(null);
    const [ advertisementCuts, setAdvertiseMentCuts ] = React.useState<imageType[]>([]);
    const [ school, setSchool ] = React.useState<string>("");
    const [ phoneNumber, setPhoneNumber ] = React.useState<string>("");

    const [ logline, setLogline ] = React.useState<string|undefined>(undefined);
    const [ character, setCharacter ] = React.useState<string|undefined>(undefined);

    const [didClickSchool, setDidClickSchool] = React.useState(false);
    
    const isBase64Included = advertisementCuts.filter( ({image}) => 
                                !image.includes("static.webtoon.today") 
                                || image.length > 5000 
                            ).length > 0;

    React.useEffect(() => {
        fetchTitle([{serviceId, titleId}]);
        fetchEpisode({serviceId, titleId});

        (async () => {
            const reports = await readUnivReport({serviceId, titleId});
            if ( reports ) {

                const res = reports[0]
                setSchool(res?.schoolInfo ||"");
                setPhoneNumber(res?.phoneNumber ||"");
    
                //3분 이내 재접수를 방지
                if ( ( res?.createdAt || 0 ) > Date.now()/1000 - (60 * 3) ) {
                    toastAlert("재접수 대상 작품이 아닙니다.", 5000);
                    fn.goto(`/contents/${serviceId}/${titleId}`);
                }
            }
        })();

        refreshTitle([{serviceId, titleId}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])

    React.useEffect(()=>{
        const key = `${serviceId}:${titleId}`;
        if ( !titleObject[key] ) return;
        
        const titleResp = titleObject[key];
        
        if ( titleResp ) {
            setTitle(titleResp ||{});
            
            setAdvertiseMentCuts(titleResp ? (titleResp?.slides || []):[]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[titleObject])

    React.useEffect(() => {
        const key = `${serviceId}:${titleId}`;
        if ( !episodeObject[key] ) return;
        
        const episodeResp = episodeObject[key] ||[];
        if ( episodeResp.length > 0 ){

            setEpisodeRange([
                Number(episodeResp[0].episodeId),
                Number(episodeResp[episodeResp.length-1].episodeId)
            ])
        } else {
            setEpisodeRange(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[episodeObject])

    return(
        <div className={'Applying2024UniversityMainArea'}>
            <div className={'Applying2024UniversityInner'}>
                <div className={'ApplyingAnalysisForm'}>
                    <div className={'NoticeSection'}>
                        <div className={'SectionTitle'} >
                            {'디렉터의 판단이 아닌 독자들의 진짜 반응으로 심사하는 유일무이의 공모전, 과제부활전🔥'}
                        </div>
                    </div>
                    <div className={'Section'} >
                        <div className={'ParticipantHeader'} >
                            <div className={'SectionTitle'}> {'참가자'} </div>
                            <div className={'RequiredSectionLabel'} > {'필수 작성'} </div>
                        </div>
                        <div className={'Divider'} />
                        <div className={'ParticipantInfo'} >
                            <WithLabel label={'학교 & 학원명'} >
                                <div className='SchoolInputBox'>
                                    <AutocompletableTextField
                                        placeholder={'소속된 학교 또는 학원명을 입력해주세요.'}
                                        candidates={[
                                            {pattern: /[가-힇]+학$/, prefix: '', postfix: '교'},
                                            {pattern: /[가-힇]+학$/, prefix: '', postfix: '원'},
                                            {pattern: /[가-힇]+대$/, prefix: '', postfix: '학교'},
                                            {pattern: /[가-힇]+고$/, prefix: '', postfix: '등학교'},
                                            {pattern: /[가-힇]+[가-닿댁-힇] *$/, prefix: '', postfix: '대학교'},
                                            {pattern: /[가-힇]+[가-곟곡-힇] *$/, prefix: '', postfix: '고등학교'},
                                            {pattern: /[가-힇]+[가-곟곡-힇] *$/, prefix: '', postfix: '아카데미'},
                                        ]}
                                        antiPattern={/학교|학원|예고|한예종|아카데미|오늘의웹툰/}
                                        onBlur={(e)=>{
                                            const value = e.target.value.trim();
                                            
                                            setSchool(value)
                                            setDidClickSchool(true)
                                        }}
                                        fullWidth style={{
                                            paddingLeft:0
                                        }}
                                        type="text"
                                        value={school}
                                        size={"small"}
                                        variant={"outlined"}
                                        InputProps={{
                                            style: {
                                                fontSize: '0.8125rem',
                                                borderRadius: 4,
                                                padding: "2px 0",
                                                height: 48,
                                            },
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            
                                            setSchool(value)
                                        }}
                                    />
                                    <div className={'CheckOutWithGuide'}>
                                        {(didClickSchool && !school)
                                            ? <span className={'Required'}>{'필수 입력 정보입니다.'}</span>
                                            :<div></div>}
                                        <TextCheckBox
                                            text={"소속이 없습니다 "}
                                            disabled={false}
                                            isChecked={school === '오늘의웹툰'}
                                            onChange={(e)=>{
                                                if (e.target.checked) {
                                                    setSchool('오늘의웹툰');
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </WithLabel>
                            <WithLabel label={'전화번호'}>
                                <GuideTextBoxForGeneral
                                    text={phoneNumber}
                                    placeholder={'‘-’ 없이 입력해주세요.'}
                                    onChange={(e) => {
                                        const value = e.target.value.trim().replace(/[^0-9]/,"");;
                                        
                                        if (maxLength >= 0) {
                                            setPhoneNumber(value.slice(0, maxLength))
                                        } else {
                                            setPhoneNumber(value)
                                        }
                                    }}
                                    onBlur={(e) => {
                                        let value = e.target.value.trim().replace(/[^0-9]/,"");

                                        if (maxLength >= 0) {
                                            setPhoneNumber(value.slice(0, maxLength))
                                        } else {
                                            setPhoneNumber(value)
                                        }
                                    }}
                                    guideTexts={{
                                        initial: '',
                                        normal: '',
                                        success: '',
                                        fail: '',
                                        required: '필수 입력 정보입니다.'
                                    }}
                                    isRequired={true}
                                    forcedGuideTextType={
                                        hasTriedApplying && phoneNumber.length === 0 
                                            ? 'required'
                                            : phoneNumber.length === 0 
                                                ? undefined 
                                                    : phoneRegExp.test(phoneNumber) 
                                                        ? 'success'
                                                        : 'fail'
                                    }
                                />
                            </WithLabel>
                        </div>
                    </div>
                    <div className={"Section"}>
                        <div className={'SectionHeader'} >
                            <div className={'SectionTitle'}> {'등록된 작품 정보'} </div>
                            <div className={'SectionSubtitle'} >
                                <div> {"데이터 분석에 활용될 필수 정보에요."} </div>
                                <div> {"수정을 원하시면"} <a className={'ClickableWord'} href={`/contents/${serviceId}/${titleId}`} onClick={fn.gotoByAnchor} >{"작품 홈"}</a><span className={'ImpressedWord'}>{" > 작품 정보 관리"}</span> {"에서 수정하실 수 있어요."} </div>
                            </div>
                        </div>
                        <div className={'Divider'} />
                        <div className={'WebtoonInfo'}>
                            <WithStateAndLabel label={'공개여부'} iconType={['public','unlist','private'].includes(title?.exposure ||"") ? 'success' : 'warning'} disabled={true} >
                                <div> {`${title?exposureLabels[title?.exposure]:""}${title?.exposure!=='public'?` → 과제부활전을 참여하시면 공개 설정으로 변경돼요.`:""}`} </div>
                            </WithStateAndLabel>
                            <WithStateAndLabel label={'작가명'} iconType={(title?.author ||"").length > 0 ? 'success' : 'warning'} disabled={true} >
                                <div> {session.name} </div>
                            </WithStateAndLabel>
                            <WithStateAndLabel label={'에피소드'} iconType={episodeRange ? 'success' : 'warning' } disabled={true} >
                                <div> {episodeRange?.map( (rangeNums) => `${rangeNums}화`).join(' - ') ||"-"} </div>
                            </WithStateAndLabel>
                            <WithStateAndLabel label={'제목'} iconType={(title?.title ||"").length > 0 ? 'success' : 'warning'} disabled={true} >
                                <div> {title?.title ||""} </div>
                            </WithStateAndLabel>
                            <WithStateAndLabel label={'줄거리'} iconType={`${title?.description ||""}`.trim().length > 0 ? 'success' : 'warning'} disabled={true}  >
                                <div> {title?.description.trim()} </div>
                            </WithStateAndLabel>
                        </div>
                    </div>
                    {advertisementCuts.length>0 && <div className={'Section'} >
                        <div className={'SectionTitle'} >
                            {'미리보기'}
                        </div>
                        <div className={'Divider'} />
                        <div className={'ApplyingInfo'}>
                            <AdsSlides title={title}/>
                        </div>
                    </div>}
                    <div className={"Section"} >
                        <div className={'ParticipantHeader'} >
                            <div className={'SectionTitle'}> {''} </div>
                            <div className={'RequiredSectionLabel'} > {'선택 작성'} </div>
                        </div>
                        <div className={'SectionHeader'} >
                            <div className={'SectionSubtitle'} >
                                <div> {"로그라인과 등장인물을 작성하면 숏드라마 부문 평가에 가산점으로 작용해요."} </div>
                            </div>
                        </div>
                        <WithLabel label={'로그 라인'}>
                            <TextArea
                                text={logline || ""}
                                onChange={value => setLogline(value)}
                                rows={10}
                                fullWidth
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: ''
                                }}
                                isRequired={true}
                                forcedGuideTextType={hasTriedApplying && (logline || "").length === 0 ? 'required' : undefined}
                                onBlur={()=>{
                                    if ( logline ) {
                                        logBehavior("input-loglineUniv2024");
                                    }
                                }}
                            />
                        </WithLabel>
                        <WithLabel label={'주요 등장인물 소개'}>
                            <TextArea
                                text={character || ""}
                                onChange={(value) => setCharacter(value) }
                                rows={10}
                                fullWidth
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: ''
                                }}
                                isRequired={true}
                                forcedGuideTextType={hasTriedApplying && (character || "").length === 0 ? 'required' : undefined}
                                onBlur={()=>{
                                    if ( character ) {
                                        logBehavior("input-characterUniv2024");
                                    }
                                }}
                            />
                        </WithLabel>
                    </div>
                    <div className={'Section'}>
                        <div className={'SectionTitle'} >
                            {'약관 동의'}
                        </div>
                        <div className={"DisclaimerBody"} >
                            <div className={'SurviceAllAgreementsBox'}>
                                <TextCheckBox
                                    text={'전체 동의'}
                                    isChecked={Object.values(agreements).every( value => value )}
                                    onChange={ (e) => {
                                        let result: {[key in keyof Applying2024AgreementType]: boolean} = {...agreements};
                                        for ( const agreement in agreements ) {
                                            const key = agreement as keyof typeof agreements; 
                                            result[key] = e.target.checked;
                                        }
                                        setAgreements(result);
                                    }} 
                                    color={'rgb(121, 120, 130)'}
                                    style={{
                                        justifyContent: 'space-between', height: 'auto',
                                        gap: 8, padding: 0, borderWidth: 0,
                                        fontSize: '0.875rem', color: 'rgb(189, 189, 194)',
                                        backgroundColor: 'rgb(255,255,255)',
                                        fontWeight: 500
                                    }}
                                    key={'전체 동의'}
                                />
                            </div>
                            <div className={'Divider'} />
                            <div className={'AgreementBox'} >
                                {Object.entries(agreements).map( ([key, value]) => (
                                    <div className={'AgreementRow'} key={key} >
                                        <div className={'MandatoryNotice'} >{'(필수)'}</div>
                                        <TextCheckBox
                                            text={AgreementsLabel[key]}
                                            isChecked={Boolean(value)}
                                            onChange={ (e) => setAgreements({...agreements, [key]: !value}) }
                                            color={'rgb(121, 120, 130)'}
                                            style={{
                                                width: '100%', justifyContent: 'space-between', 
                                                gap: 8, padding: 0, borderWidth: 0,
                                                fontSize: '0.875rem', color: 'rgb(25, 24, 27)',
                                                backgroundColor: 'rgb(255,255,255)',
                                                fontWeight: 600
                                            }}
                                            key={AgreementsLabel[key]}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={'ApplyingAnalysisFooter'}>
                        <Button className={`ApplyingAnalysisButton`} fullWidth disableElevation variant={'contained'} color={'primary'} onClick={()=>{
                            (async()=>{
                                setHasTriedApplying(true);
                                if ( !title || !episodeRange ) {
                                    
                                    return
                                };
                                setIsLoading(true);

                                if ( isBase64Included ) {
                                    toastAlert("광고컷 이미지가 업로드 중입니다. \n문제가 반복되면 홍보컷을 다시 업로드 해 주세요.");
                                    setIsLoading(false);
                                    return;
                                }

                                const currentTitle = (await getTitle(serviceId, titleId))[0];

                                if (currentTitle.exposure !== 'public'){
                                    const response = await updateTitle({...currentTitle, exposure: 'public'});
                                    
                                    if (!response) {
                                        logBehavior('change-exposure(fail)', {message: '작품 public 변경 실패', currentTitle, response});
                                    } else {
                                        logBehavior('change-exposure(success)', {message: '작품 public 변경 성공', currentTitle, response});
                                    };
                                };

                                const service = '2024schoolCompetition'

                                logBehavior('click-applicationbutton',{
                                    consentList, currentTitle,
                                    name: session.name, episodeRange,
                                    title: title.title, serviceId, titleId, school, service,
                                    introduction: title.description, advertisementCuts, requestCreatives: false,
                                    currentTermsofuseVersion, phoneNumber, logline, character
                                })

                                let res = await AnalyticsSubmit({
                                    name: session.name ||"", episodeRange,
                                    title: title.title, serviceId, titleId, school, service,
                                    introduction: title.description, advertisementCuts, requestCreatives: false,
                                    consentList, currentTermsofuseVersion, phoneNumber, logline, character
                                });
                                
                                if (!res){
                                    toastAlert("다시 접수를 시도해주세요.", 5000, "warning");
                                }else{
                                    fn.goto(`/contents/${serviceId}/${titleId}`);
                                    toastAlert("접수를 완료했어요. ", 6000, "success");
                                }

                                setIsLoading(false);
                            })();
                        }} disabled={
                            advertisementCuts.length < 1
                            || isBase64Included
                            || !episodeRange
                            || (title?.description ||"").trim().length < 1
                            || isLoading
                            || !phoneNumber
                            || !(school && school.length > 0)
                            || !Object.values(agreements).every( value => value )
                        }>
                            {'과제부활전 참여하기'}
                        </Button>
                    </div>
                </div>
                <LoadingCircle show={isLoading} />
            </div>
        </div>
    )
}

const WithStateAndLabel = ({
    label, iconType, children, disabled = false
}:{
    label: string, iconType?: 'success'|'error'|'warning'|'info', children: ReactNode, disabled?: boolean
}) => {
    return(<div className={'WithStateAndLabel'} >
        <div className={'Label'} >
            <div className={'LabelInner'}>
                <img src={`https://static.webtoon.today/ddah/icon/icon_${iconType}.svg`} alt={iconType} width={20} height={20} style={{marginRight: 8}} />
                {label}
            </div>
        </div>
        <div className={`Content ${disabled?"Disabled":""}`} >
            {children}
        </div>
    </div>)
}

export default Applying2024University;