import React, { useEffect, useRef } from 'react';
import { fn } from '../../Functions';
import { logBehavior } from '../../Data/Behavior';


const YoutubeAD = ({videoId, shortlink}: {videoId: string, shortlink:string}) => {

    const player = useRef<any>(null);
    useEffect(()=>{
        try{
            player.current = new (window as any).YT.Player('player',{
                height: '600',
                width: '340',
                videoId,
                playerVars: {
                    'autoplay': 0,
                    'controls': 0,
                    'disablekb': 1,
                    'modestbranding': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'loop': 0,
                    'playlist': videoId
                },
            });
            logBehavior('ad-expose', {ad: 'youtube', videoId, shortlink});
        }catch(e){
            console.error(e);
        }

    },[shortlink, videoId]);

    return (
        <div className={'YoutubeAD'} style={{position: "relative", paddingBottom: 100}}>
            <div id='player'></div>
            <a
                style={{position:'absolute', width: "100%", height: "100%", top: 0, left: 0}}
                href={shortlink}
                onClick={(e) => {
                    console.log(`(window as any).YT?.PlayerState: ${JSON.stringify((window as any).YT?.PlayerState)}`);
                    console.log(player.current);
                    e.preventDefault();
                    if (player.current?.getPlayerState() !== 1){
                        player.current?.playVideo();
                        logBehavior('ad-start', {ad: 'youtube', videoId, shortlink});
                    } else {
                        logBehavior('ad-conversion', {ad: 'youtube', videoId, shortlink});
                        fn.gotoByAnchor(e);
                    }
                }}
            >
                <div style={{width: "100%", height: "100%"}}></div>
            </a>
        </div>
    )
}

export default YoutubeAD;